import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>
        Hello from Habit Master
        </h1>
      
    </div>
  );
}

export default App;
